import React from 'react';
import { bindings, hook } from '@vl/redata';
import useLoadingModel from '@vl/hooks/useLoadingModel';

const bindData = bindings({
  pageContainer: {
    rules: [
      [
        'data',
        {
          data: {
            loadingModel: hook(useLoadingModel(() => ({ fetchProfile: false }))),
          },
        },
      ],
    ],
  },

  // notLoading: {
  //   rules: [
  //     [
  //       'display',
  //       {
  //         display: hook((ctx) => !ctx.apply('loadingModel.isLoading')),
  //       },
  //     ],
  //   ],
  // },
});

export default bindData;
