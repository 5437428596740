import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';

import { ctx } from '@vl/redata';
import useRoute from '@vl/hooks/useGbRouteDe';
// import FormGuestLogin from '@uz/unitz-components-web/FormGuestLogin';
import LoadingScreen from '@uz/unitz-components-web/LoadingScreen';
import AdvisorModel from '@uz/unitz-models/AdvisorModel';
import useAsyncLoadingCall from '@vl/hooks/useAsyncLoadingCall';
import { isSSR } from '@vl/mod-utils/platform';

import _ from 'lodash';
import AsyncRender from '@uz/unitz-components-web/AsyncRender';
import hasuraClient from '@vl/mod-clients/hasuraApp';
import VerifyEmailPage from '@uz/unitz-tool-pages/VerifyEmailPage';

export const Redirect = ({ to }) => {
  const route = useRoute();
  React.useEffect(() => {
    route.navigateExternal(to);
  });
  return null;
};

export const AuthRedirect = ({ to, children }) => {
  const ref = React.useRef({});
  return (
    <DIV>
      {(() => {
        if (isSSR()) {
          return <LoadingScreen />;
        }

        const isAuthenticated = ctx.apply('authModel.isAuthenticated');
        ref.current.isAuthenticated = isAuthenticated;
        ref.current.isAuthenticatedIniting = ctx.get('authModel.initing');
        if (ref.current.isAuthenticatedIniting) {
          return null;
        }

        if (!ref.current.isAuthenticated) {
          return children;
        }
        return <Redirect to={to} />;
      })()}
    </DIV>
  );
};

const checkEmailVerified = (currentUser) => {
  const emailVerified = _.get(currentUser, 'emailVerified');
  const isValid = _.has(currentUser, 'emailVerified');
  const providerData = _.get(currentUser, 'providerData');
  if (!isValid) return true;
  if (_.get(providerData, 'length') === 1 && _.get(providerData, '0.providerId') === 'password') {
    return !!emailVerified;
  }
  return true;
};

export const UnAuthRedirect = ({ to, children }) => {
  const ref = React.useRef({});
  return (
    <DIV>
      {(() => {
        if (isSSR()) {
          return <LoadingScreen />;
        }

        const isAuthenticated = ctx.apply('authModel.isAuthenticated');
        ref.current.isAuthenticated = isAuthenticated;
        ref.current.isAuthenticatedIniting = ctx.get('authModel.initing');

        if (ref.current.isAuthenticatedIniting) {
          return null;
        }

        const currentUser = ctx.get('authModel.currentUser');
        const isEmailVerified = checkEmailVerified(currentUser);
        if (!isEmailVerified) {
          return <VerifyEmailPage />;
        }
        if (ref.current.isAuthenticated) {
          return children;
        }
        return <Redirect to={to} />;
      })()}
    </DIV>
  );
};

export const AdvisorOnlyRedirect = ({ to, children }) => {
  const ref = React.useRef({});
  const [advisor, findAdvisorFn, loadingAdvisor] = useAsyncLoadingCall(async (userId) => {
    let advisor;

    try {
      advisor = await AdvisorModel.find(
        `where: {id: {_eq: "${userId}"}}`,
        `profile {
          display_name
          avatar_url
        }
        `
      );
      if (advisor) {
        advisor = _.first(advisor.toObject());
      }
    } catch (err) {
      console.log('load advisor error', err);
    }
    return advisor;
  });
  return (
    <DIV>
      {(() => {
        if (isSSR()) {
          return <LoadingScreen />;
        }

        const isAuthenticated = ctx.apply('authModel.isAuthenticated');
        ref.current.isAuthenticated = isAuthenticated;
        ref.current.isAuthenticatedIniting = ctx.get('authModel.initing');

        if (ref.current.isAuthenticatedIniting || loadingAdvisor) {
          return (
            <div className="flex items-center justify-center">
              <LoadingScreen />
            </div>
          );
        }

        if (!ref.current.isAuthenticated && !!to) {
          return <Redirect to={to} />;
        }

        if (ref.current.isAuthenticated && !findAdvisorFn.called) {
          const userId = ctx.apply('authModel.getUserId');
          findAdvisorFn(userId);
          return <LoadingScreen />;
        }

        if (advisor) {
          return children;
        }
        if (!!to) {
          return <Redirect to={to} />;
        }
        return null;
      })()}
    </DIV>
  );
};

export const GuestRedirect = ({ to, children }) => {
  const [guestLogin, $guestLogin] = React.useState(false);
  const [guestUser, $guestUser] = React.useState();
  const ref = React.useRef({});
  _.assign(ref.current, { guestLogin, $guestLogin, guestUser, $guestUser });
  return (
    <DIV>
      {(() => {
        if (isSSR()) {
          return <LoadingScreen />;
        }
        ctx.apply('REF.setRef', 'guestControlModelRef', ref);
        const isAuthenticated = ctx.apply('authModel.isAuthenticated');
        ref.current.isAuthenticated = isAuthenticated;
        ref.current.isAuthenticatedIniting = ctx.get('authModel.initing');

        // if (ref.current.isAuthenticatedIniting || ref.current.guestLogin) {
        //   return <LoadingScreen />;
        // }

        if (ref.current.guestUser) {
          return children;
        }

        if (ref.current.isAuthenticated) {
          return children;
        }
        return ctx.apply('authModel.login');
      })()}
    </DIV>
  );
};

const isCustomDomain = () => {
  if (typeof window !== 'undefined') {
    const slug = _.get(window.pageDomain, 'slug');
    return !!slug;
  }
};

export const DefaultAccountRedirect = ({ children }) => {
  const ref = React.useRef({});
  const route = useRoute();
  let pathname = !isSSR() && _.get(window, 'location.pathname');
  pathname = _.trimEnd(pathname, '/');
  if (!['', '/en', '/de', '/vi'].includes(pathname) || isCustomDomain()) return children;

  return (
    <DIV forceCtx>
      {ctx.debug(() => {
        const account_id = ctx.apply('accountModel.getAccountId');
        ref.current.account_id = account_id;
        const user_id = ctx.apply('authModel.getUserId');
        ref.current.user_id = user_id;
      })}
      <AsyncRender
        render={async () => {
          if (isSSR()) {
            return <LoadingScreen />;
          }
          const currentUser = ctx.get('authModel.currentUser');
          const isEmailVerified = checkEmailVerified(currentUser);
          if (!isEmailVerified) {
            return <VerifyEmailPage />;
          }

          const { account_id, user_id } = ref.current;

          if (account_id && user_id) {
            const resData = await hasuraClient.getClient().query(
              `query MyQuery {
                    b2b_account(
                      where: {id: {_eq: "${account_id}"}}
                    ) {
                      id
                      slug
                      account_profile {
                        id
                        display_name
                        avatar_url
                      }
                    }
                  }`,
              {}
            );
            if (resData) {
              const accountUrl = ctx.apply('routeStore.toUrl', 'toolAccountDetail', _.get(resData, 'b2b_account.0'));
              console.log('redirect account 1', accountUrl);
              // route.navigateExternal(accountUrl);
              setTimeout(() => {
                route.navigateExternal(accountUrl);
              }, 1000 * 2);
              return <LoadingScreen />;
            }
          }
          if (!account_id && user_id) {
            const accountsRes = await hasuraClient.getClient().query(
              `query MyQuery {
                  b2b_account (where: {members: {user_id: {_eq: "${user_id}"}}}) {
                    id
                    slug
                    account_profile {
                      id
                      display_name
                      avatar_url
                    }
                  }
                }`,
              {}
            );
            const accounts = _.get(accountsRes, 'b2b_account', []);
            const hasAccount = !!_.last(accounts);

            if (hasAccount) {
              const accountUrl = ctx.apply('routeStore.toUrl', 'toolAccountDetail', _.last(accounts));
              console.log('redirect account 2', accountUrl);
              setTimeout(() => {
                route.navigateExternal(accountUrl);
              }, 1000 * 2);
              return <LoadingScreen />;
            }
            route.navigate(ctx.apply('routeStore.toUrl', 'toolCreateAccount'));
          }

          route.navigate(ctx.apply('routeStore.toUrl', 'toolCreateAccount'));

          // redirect create account page
          return children;
        }}
        rendering={<LoadingScreen />}
      />
    </DIV>
  );
};
