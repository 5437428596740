import React from 'react';

import { Button } from '@uz/unitz-components-web/Button';

const Announcement = (props) => {
  return (
    <div className="px-2">
      {/* <a
        href={`https://zoom.us/oauth/authorize?response_type=code&client_id=pGEImldqTKi7EUaRC0FBhQ&redirect_uri=https%3A%2F%2Funitz-zoom.web.app%2Fzoom_oauth`}
      >
        Click to install
      </a>
      <Button
        className="mx-2"
        type="primary"
        size="small"
        onClick={() => {
          window.location.href = `https://us06web.zoom.us/j/7124174735?pwd=dWFvS1NxN1ZpWUxjbm41REk2c0NSZz09`;
        }}
      >
        Join meeting
      </Button> */}
    </div>
  );
};

export default Announcement;
