import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import { Row, Col, Alert } from 'antd';
import { DefaultAccountRedirect } from '@uz/unitz-tool-components/AppRedirect';
import _ from 'lodash';
import LoginPage from '../LoginPage';

const Index = () => {
  // @TODO: validate missing required input
  return (
    <DIV className="pageContainer">
      {!ctx.apply('currentUserModel.getUserId') ? (
        <LoginPage />
      ) : (
        <DefaultAccountRedirect>
          <div className="p-4 h-full"></div>
        </DefaultAccountRedirect>
      )}
    </DIV>
  );
};

export default displayName(Index);
