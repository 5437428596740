import { bindings, hook } from '@vl/redata';
import useLoadingModel from '@vl/hooks/useLoadingModel';
import _ from 'lodash';
import useRoute from '@vl/hooks/useGbRouteDe';
import Url from 'url-parse';
import qs from 'query-string';

const bindData = bindings({
  pageContainer: {
    rules: [
      [
        'data',
        {
          data: {
            loadingModel: hook(useLoadingModel(() => ({ fetchProfile: false }))),
            item: hook((ctx) => {
              const route = useRoute();
              const routeParams = route.getParams();
              const redirect_url = _.get(routeParams, 'redirect_url');

              if (!redirect_url) return null;
              const redirectUrlObj = new Url(redirect_url);
              const qsObj = qs.parse(redirectUrlObj.query);

              const advisorId = _.get(qsObj, 'advisor_id');

              if (!advisorId) return null;

              return null;
            }),
          },
        },
      ],
    ],
  },
  isLoading: {
    rules: [
      [
        'display',
        {
          display: hook((ctx) => {
            return ctx.get('authModel.isLoadingRedirectResult');
          }),
        },
      ],
    ],
  },
  // notLoading: {
  //   rules: [
  //     [
  //       'display',
  //       {
  //         display: hook((ctx) => !ctx.apply('loadingModel.isLoading')),
  //       },
  //     ],
  //   ],
  // },
});

export default bindData;
